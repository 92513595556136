// from https://itnext.io/how-to-build-a-floating-label-input-field-f9b21669fe2f
// removed placeholder handling, as it resulted in a "null" appearing when there's no placeholder
$(() => {
  const FloatLabel = (() => {
    // add active class and placeholder
    const handleFocus = (e) => {
      e.target.parentNode.classList.add('active');
    };

    // remove active class and placeholder
    const handleBlur = (e) => {
      if (!e.target.value) {
        e.target.parentNode.classList.remove('active');
      }
    };

    // register events
    const bindEvents = (element) => {
      const floatField = element.querySelector('input');
      floatField.addEventListener('focus', handleFocus);
      floatField.addEventListener('blur', handleBlur);
    };

    // get DOM elements
    const init = () => {
      const floatContainers = document.querySelectorAll('.float-label-container');

      floatContainers.forEach((element) => {
        if (element.querySelector('input').value) {
          element.classList.add('active');
        }

        bindEvents(element);
      });
    };

    return { init };
  })();

  FloatLabel.init();
});
