import Highcharts from 'highcharts';

require('highcharts/modules/funnel')(Highcharts);

$(() => {
  // RG CALCULATOR USAGE CHART
  const $calculator_chart_div = $('#rg-calculator-usage-chart');

  if ($calculator_chart_div.length) {
    const point_width = 26;

    Highcharts.chart('rg-calculator-usage-chart', {
      chart: {
        styledMode: true,
        type: 'bar',
      },
      credits: { enabled: false },
      legend: { enabled: false },
      plotOptions: {
        series: {
          dataLabels: {
            align: 'left',
            enabled: true,
            inside: true,
            formatter() {
              const color = this.y === 0 ? 'black' : 'white';
              return `<span style="color: ${color}">${this.y}</span>`;
            }
          },
          pointWidth: point_width
        }
      },
      series: $calculator_chart_div.data('calculator-series'),
      title: {
        align: 'left',
        margin: 20,
        text: $calculator_chart_div.data('title')
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '{point.y} analyses started'
      },
      xAxis: {
        categories: $calculator_chart_div.data('calculator-names')
      },
      yAxis: {
        visible: false
      }
    }, (chart) => { // chart is too tall without this - https://www.highcharts.com/forum/viewtopic.php?t=32737
      const w = $('#rg-calculator-usage-chart').width();
      const h = $('#rg-calculator-usage-chart').height();
      const box = chart.plotBox;
      const len = chart.series[0].data.length;

      chart.setSize(w, h - box.height + len * point_width * 1.4);
    });
  }

  // REP PERFORMANCE CHART

  const $rg_chart_div = $('#rep-performance-chart');
  let rg_chart = null;

  if ($rg_chart_div.length) {
    rg_chart = Highcharts.chart('rep-performance-chart', {
      chart: {
        styledMode: true,
        type: 'scatter',
        zoomType: 'xy'
      },
      credits: { enabled: false },
      legend: { enabled: false },
      plotOptions: {
        series: {
          dataLabels: {
            align: 'left',
            enabled: true,
            format: '{point.name}'
          }
        }
      },
      tooltip: {
        formatter() {
          return `<strong>${this.key}</strong><br>${Math.round(this.y)} started, ${Math.round(this.x)} sent`;
        }
      },
      series: $rg_chart_div.data('saved-vs-sent-series'),
      title: {
        align: 'left',
        margin: 32,
        text: $rg_chart_div.data('title')
      },
      xAxis: {
        allowDecimals: false,
        max: $rg_chart_div.data('max-sent') + 1,
        min: 0,
        title: {
          enabled: true,
          text: 'Number of analyses saved & sent to prospects'
        }
      },
      yAxis: {
        allowDecimals: false,
        gridLineWidth: 0,
        max: $rg_chart_div.data('max-saved') + 1,
        min: 0,
        title: {
          text: 'Number of analyses started by reps'
        }
      }
    }, (chart) => {
      const width = chart.plotBox.width / 2.0;
      const height = (chart.plotBox.height / 2.0) + 1;
      const x_left = 15;
      const y_top = 25;
      const x_top_right = width * 0.7;
      const y_bottom = 125;
      const x_bottom_right = width * 0.5;

      // ACTIVE/LESS PRODUCTIVE
      chart.renderer.rect(chart.plotBox.x, chart.plotBox.y, width, height, 1).attr({
        borderColor: '#1000',
        borderWidth: '1',
        fill: '#F5F5F5',
        zIndex: 0
      }).add();

      // POWER USERS
      chart.renderer.rect(chart.plotBox.x + width, chart.plotBox.y, width, height, 1).attr({
        fill: '#d2deef',
        zIndex: 0
      }).add();

      // LIGHTER USERS
      chart.renderer.rect(chart.plotBox.x, chart.plotBox.y + height, width, height, 1).attr({
        fill: '#f7f8fc',
        zIndex: 0
      }).add();

      // PRODUCTIVE/LESS ACTIVE
      chart.renderer.rect(chart.plotBox.x + width, chart.plotBox.y + height, width, height, 1).attr({
        fill: '#F5F5F5',
        zIndex: 0
      }).add();
      chart.renderer.text('ACTIVE / LESS PRODUCTIVE', chart.plotBox.x + x_left, chart.plotBox.y + y_top).attr({
        zIndex: 1
      }).css({
        color: '#474747'
      }).add();
      chart.renderer.text('POWER USERS', chart.plotBox.x + width + x_top_right, chart.plotBox.y + y_top).attr({
        zIndex: 1
      }).css({
        color: '#1c1d4d',
      }).add();
      chart.renderer.text('LIGHTER USERS', chart.plotBox.x + x_left, chart.plotBox.y + height + y_bottom).attr({
        zIndex: 1
      }).css({
        color: '#1c1d4d',
      }).add();
      return chart.renderer.text(
        'PRODUCTIVE / LESS ACTIVE', chart.plotBox.x + width + x_bottom_right, chart.plotBox.y + height + y_bottom
      ).attr({
        zIndex: 1
      }).css({
        color: '#474747',
      }).add();
    });
  }

  // SS FUNNEL CHART

  const $ss_chart_div = $('#ss-funnel-chart');
  let ss_chart = null;

  if ($ss_chart_div.length) {
    ss_chart = Highcharts.chart('ss-funnel-chart', {
      chart: {
        styledMode: true,
        type: 'funnel',
      },
      credits: { enabled: false },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b> ({point.y:,.0f})',
            softConnector: true
          },
          neckWidth: '30%',
          neckHeight: '25%'
        }
      },
      series: $ss_chart_div.data('calculator-series'),
      title: {
        align: 'left',
        margin: 32,
        text: $ss_chart_div.data('title')
      },
      tooltip: {
        formatter() {
          return `${this.key} - ${$ss_chart_div.data('chart-details')[this.key]}`;
        }
      }
    });
  }

  // DATE PICKER

  const report_range_initialize = (start, end) => $('#report-range span').html(
    `${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`
  );

  report_range_initialize(moment().subtract(29, 'days'), moment());

  const report_range_callback = function(start, end) {
    // if we're on a page for a specific calculator, the chart will have the calculator ID
    const url = (() => {
      if ($('div[data-calculator-id]').length > 0) {
        const calculator_id = $('div[data-calculator-id]').first().data('calculator-id');
        return `/admin/reports/${calculator_id}`;
      }
      return '/admin/dashboard';
    })();

    $.ajax({
      type: 'POST',
      url,
      data: {
        _method: 'PUT',
        start_date: start.format('YYYY-MM-DD'),
        end_date: end.format('YYYY-MM-DD')
      },
      dataType: 'json',
      success(response) {
        if (response.rg_total_sessions != null) {
          $('.rep-guided-activity .total-sessions-js').text(response.rg_total_sessions);
          $('.rep-guided-activity .total-saved-sessions-js').text(response.rg_total_saved_sessions);
          $('.rep-guided-activity .total-saved-and-sent-sessions-js').text(response.rg_total_saved_and_sent_sessions);
          $('.rep-guided-activity').addClass('activity-updated-js');

          if (rg_chart) {
            const rep_new_performance_series = $.parseJSON(response.rg_saved_vs_sent_data_series);

            // all the "false" flags tell Highcharts not to redraw - we'll redraw after we update all the data
            while (rg_chart.series.length > 0) {
              rg_chart.series[0].remove(false);
            }

            let i = 0;
            while (i < rep_new_performance_series.length) {
              rg_chart.addSeries(rep_new_performance_series[i], false);
              i += 1;
            }

            rg_chart.xAxis[0].setExtremes(0, parseInt(response.rg_max_sent, 10) + 1, false);
            rg_chart.yAxis[0].setExtremes(0, parseInt(response.rg_max_saved, 10) + 1, false);
            rg_chart.redraw();

            if (response.rg_calculator_data_series != null) { // for dashboard page only
              $calculator_chart_div.highcharts().series[0].setData(
                response.rg_calculator_data_series[0].data
              );
            }
          }
        }

        if (ss_chart && response.ss_total_sessions != null) {
          $('.self-service-activity .total-sessions-js').text(response.ss_total_sessions);
          $('.self-service-activity .total-saved-sessions-js').text(response.ss_total_saved_sessions);
          $('.self-service-activity .total-saved-and-sent-sessions-js').text(response.ss_total_saved_and_sent_sessions);
          $('.self-service-activity').addClass('activity-updated-js');

          $ss_chart_div.data('chart-details', response.ss_funnel_details);
          ss_chart.series[0].setData(response.ss_funnel_data_series[0].data, false);
          ss_chart.redraw();

          if (response.ss_total_sessions === 0) {
            $ss_chart_div.hide();
          } else {
            $ss_chart_div.show();
          }
        }
      }
    });

    return $('#report-range span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`);
  };

  const date_range_options = {
    autoApply: false,
    ranges: {
      'Past 7 Days': [
        moment().subtract(6, 'days'),
        moment()
      ],
      'Past 30 Days': [
        moment().subtract(29, 'days'),
        moment()
      ],
      'Past 3 Months': [
        moment().subtract(3, 'month'),
        moment()
      ],
      'Past 6 Months': [
        moment().subtract(6, 'month'),
        moment()
      ],
      'Past 1 Year': [
        moment().subtract(12, 'month')
      ],
      'Past 2 Years': [
        moment().subtract(24, 'month')
      ],
      'Past 3 Years': [
        moment().subtract(36, 'month')
      ]
    }
  };

  $('#report-range').daterangepicker(date_range_options, report_range_callback);

  const analysis_range_initialize = (start, end) => $('#analysis-range span').html(
    `${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`
  );

  analysis_range_initialize(moment($('#start-date').val()), moment($('#end-date').val()));

  const analysis_range_callback = function(start, end) {
    $('#start-date').val(start.format('YYYY-MM-DD'));
    $('#end-date').val(end.format('YYYY-MM-DD'));
    return $('#analysis-range span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`);
  };

  return $('#analysis-range').daterangepicker(date_range_options, analysis_range_callback);
});
