$(() => {
  // the ROI Models menu is open by default after a client is picked - close it if we're not on one of its pages
  if ($('a[href="#roi-models-menu"]').find('.active').length === 0) {
    $(this).removeClass('show');
  }

  // open the appropriate calculator nav menu
  $('.nav-item .active').closest('.collapse').addClass('show');

  // update the state of the navbar menu arrows as appropriate
  $('a[aria-expanded]').each(function() {
    if ($(this).closest('.nav-item').find('.active').length > 0) {
      $(this).attr('aria-expanded', 'true');
    }
  });

  // pass the calculator path to the form for starting the session and clear the info from the previous prospect
  $('a[data-target="#start-calculator-session-modal"]').click(function() {
    $('#start-calculator-session-form input[type="text"]').val('');
    $('#start-calculator-session-form input[name="path"]').val($(this).data('path'));

    const rg_pricing_optional = $(this).data('repGuidedPricingOptional');
    if (rg_pricing_optional === true) {
      $('.hide-pricing-row').slideDown(0);
    } else {
      $('.hide-pricing-row').slideUp(0);
    }

    return true;
  });

  // validate the form for starting the session, and close the modal on success
  $('#start-calculator-session-form').submit(function() {
    $(this).find('input').each(function() {
      const $container = $(this).closest('.float-label-container');
      const $label = $container.find('label');

      // Skip optional inputs
      if ($(this).hasClass('optional')) { return true; }

      if ($(this).val().length > 0) {
        $container.removeClass('is-invalid');
        $label.removeClass('text-danger');
      } else {
        $container.addClass('is-invalid');
        $label.addClass('text-danger');
      }
    });

    if ($(this).find('.is-invalid').length > 0) { return false; }
    $('#start-calculator-session-modal').modal('hide');
    return true;
  });

  // clear any error classes as the user goes
  $('#start-calculator-session-form input').blur(function() {
    if ($(this).val().length > 0) {
      const $container = $(this).closest('.float-label-container');
      const $label = $container.find('label');
      $container.removeClass('is-invalid');
      $label.removeClass('text-danger');
    }
  });
});
