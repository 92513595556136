import Quill from 'quill/dist/quill';

$(() => {
  const $quill_editor = $('.quill-content-editor-js');
  const $quill_source = $('.quill-content-source-js');
  if (!$quill_editor.length || !$quill_source.length) {
    return true;
  }

  const toolbarOptions = [
    [{
      header: [
        1,
        2,
        3,
        4,
        5,
        6,
        false
      ]
    }],
    [
      'bold',
      'italic',
      'underline'
    ],
    [
      { list: 'ordered' },
      { list: 'bullet' }
    ],
    [
      { indent: '-1' },
      { indent: '+1' }
    ],
    [
      {
        color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc',
          '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966',
          '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444',
          '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color']
      }
    ],
    [{ font: [] }],
    [{ align: [] }],
    ['clean']
  ];
  const quill = new Quill('.quill-content-editor-js', {
    modules: {
      toolbar: toolbarOptions
    },
    theme: 'snow'
  });

  // customize the color tool handler - from https://stackoverflow.com/a/48156458/595962
  quill.getModule('toolbar').addHandler('color', (initial_color) => {
    /* eslint-disable-next-line no-alert */
    const final_color = initial_color === 'custom-color' ? window.prompt('Enter Hex/RGB/RGBA') : initial_color;
    quill.format('color', final_color);
  });

  quill.root.innerHTML = $quill_source.val();
  return $quill_source.closest('form').submit(() => $quill_source.val(quill.root.innerHTML));
});
