$(() => {
  $('.archive-link, .edit-link').click(function() {
    $(this).closest('tr').find('.link-spinner').show();
  });

  $('.archive-link').on('ajax:success', (event, xhr) => {
    $('.link-spinner').hide();
    $(`#cvm-row-${xhr.cvm_id}`).hide('slow');
  });

  $('.archive-link').on('ajax:error', () => {
    $('.link-spinner').hide();
    alert('Failed to update the session. Please try again later.'); // eslint-disable-line no-undef, no-alert
  });

  $('.edit-link').on('ajax:success', (event, xhr) => {
    $('#edit-prospect-org-name').val(xhr.prospect_org_name);
    $('#edit-contact-first-name').val(xhr.contact_first_name);
    $('#edit-contact-last-name').val(xhr.contact_last_name);
    $('#edit-contact-email').val(xhr.contact_email);
    $('#edit-salesforce-opportunity-id').val(xhr.salesforce_opportunity_id);
    $('#edit-cvm-id').val(xhr.cvm_id);

    if ('hide_pricing' in xhr) {
      $('#hide-pricing-container-edit').slideDown(0);
      $('#edit-hide-pricing').attr('disabled', false);
      $('#hide-pricing-container-edit').insertBefore($('#edit-prospect-form input[type="submit"]').parent().parent());
      if (xhr.hide_pricing) {
        $('#edit-hide-pricing').attr('checked', true);
      } else {
        $('#edit-hide-pricing').attr('checked', false);
      }
    } else {
      $('#hide-pricing-container-edit').appendTo($('footer'));
      $('#hide-pricing-container-edit').slideUp(0);
      $('#edit-hide-pricing').attr('disabled', true);
    }

    $('#edit-prospect-modal').modal('show');
    $('.link-spinner').hide();
  });

  $('.edit-link').on('ajax:error', () => {
    $('.link-spinner').hide();
    alert('Failed to retrieve prospect information. Please try again later.'); // eslint-disable-line no-undef, no-alert
  });

  $('#edit-prospect-form').submit(function() {
    $(this).find('input').each(function() {
      const $container = $(this).closest('.float-label-container');
      const $label = $container.find('label');

      // Skip optional inputs
      if ($(this).hasClass('optional')) { return true; }

      if ($(this).val().length > 0) {
        $container.removeClass('is-invalid');
        $label.removeClass('text-danger');
      } else {
        $container.addClass('is-invalid');
        $label.addClass('text-danger');
      }
    });

    if ($(this).find('.is-invalid').length > 0) { return false; }
    $('.form-spinner').show();
    return true;
  });

  $('#edit-prospect-form').on('ajax:success', (event, xhr) => {
    $(`#cvm-row-${xhr.cvm_id} .prospect-org-name`).text(xhr.prospect_org_name);
    $('.form-spinner').hide();
    $('#edit-prospect-modal').modal('hide');
    $('#edit-prospect-form .form-control').val('');
  });

  $('#edit-prospect-form').on('ajax:error', () => {
    $('.form-spinner').hide();
    alert('Failed to update prospect information. Please try again later.'); // eslint-disable-line no-undef, no-alert
  });
});
