import '../src/javascripts/application';
import 'bootstrap-daterangepicker/daterangepicker';
import 'chosen-js/chosen.jquery';
import 'select2';
import '../src/javascripts/admin/quill_editor';
import '../src/javascripts/admin/report_charts_and_date_range';
import '../src/javascripts/admin/ss_report';
import '../src/javascripts/admin/float_label';
import '../src/javascripts/admin/navbar_and_rg_session_form';
import '../src/javascripts/admin/rg_analyses';
import '../src/stylesheets/admin.scss';

// Chosen setup
$(() => $('.chosen-select').chosen({
  allow_single_deselect: true,
  no_results_text: 'No results matched',
  width: '200px'
}));
