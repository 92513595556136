$(() => {
  const report_range_initialize = (start, end) => $('#ss-report-range span').html(
    `${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`
  );

  report_range_initialize(moment().subtract(29, 'days'), moment());

  const date_range_options = {
    autoApply: false,
    ranges: {
      'Past 7 Days': [
        moment().subtract(6, 'days'),
        moment()
      ],
      'Past 30 Days': [
        moment().subtract(29, 'days'),
        moment()
      ],
      'Past 3 Months': [
        moment().subtract(3, 'month'),
        moment()
      ],
      'Past 6 Months': [
        moment().subtract(6, 'month'),
        moment()
      ],
      'Past 1 Year': [
        moment().subtract(12, 'month')
      ],
      'Past 2 Years': [
        moment().subtract(24, 'month')
      ],
      'Past 3 Years': [
        moment().subtract(36, 'month')
      ]
    }
  };

  const report_range_callback = function(start, end) {
    $('#start_date').val(`${start.format('YYYY-MM-DD')}`);
    $('#end_date').val(`${end.format('YYYY-MM-DD')}`);

    return $('#ss-report-range span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`);
  };

  $('#ss-report-range').daterangepicker(date_range_options, report_range_callback);
});
